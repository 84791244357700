import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import ReactPaginate from "react-paginate";
import { BsFillCircleFill } from "react-icons/bs";
import { useExchangeRate } from "../hooks/usePriceConversion";
import { RxCross2 } from "react-icons/rx";
import Modal from "@mui/material/Modal";

const ArtworksSection = ({
  itemsPerPage,
  data = [],
  pagination,
  filterBar,
  minColumns = 1,
  fullSizeOnClick = false,
  price = true,
}) => {
  const [artworks, setArtworks] = useState([]);
  const [currentArtworks, setCurrentArtworks] = useState(null);
  const [currentArtwork, setCurrentArtwork] = useState(null);
  const [pageCount, setPageCount] = useState(
    Math.ceil(data?.length / itemsPerPage)
  );
  const [currentPage, setCurrentPage] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  const [allVariants, setAllVariants] = useState([]);
  const [filterVariantActivated, setFilterVariantActivated] = useState([]);
  const [openFullImageModal, setOpenFullImageModal] = useState(false);
  const { totalPriceWithVAT } = useExchangeRate();

  const splitString = (stringToSplit, separator) => {
    const arrayOfStrings = stringToSplit.split(separator);

    return arrayOfStrings;
  };

  useEffect(() => {
    const getFilterVariants = () => {
      let result = [];

      for (const artwork of data) {
        const variants = splitString(artwork.variant, ",");

        if (variants.length > 1) {
          for (const elem of variants) {
            if (!result.includes(elem)) {
              result.push(elem);
            }
          }
        } else {
          if (!result.includes(variants[0])) {
            if (variants[0] === "Essentials") {
              result.unshift(variants[0]);
            } else {
              result.push(variants[0]);
            }
          }
        }
      }

      setAllVariants(result);
    };

    if (filterBar) {
      getFilterVariants();
    }
  }, []);

  useEffect(() => {
    setItemOffset(0);
    setCurrentPage(0);

    const updateArtworksByVariant = () => {
      var result = data.filter((artwork) => {
        for (var variant in filterVariantActivated) {
          const variants = splitString(artwork.variant, ",");

          if (variants.length > 1) {
            for (const elem of variants) {
              if (elem === filterVariantActivated[variant]) return true;
            }
          } else {
            if (variants[0] === filterVariantActivated[variant]) return true;
          }
        }
        return false;
      });

      setArtworks(result);
    };

    if (filterVariantActivated.length === 0) {
      if (data) {
        setArtworks(data);
      }
    } else {
      updateArtworksByVariant();
    }
  }, [filterVariantActivated]);

  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage;

    setCurrentArtworks(artworks.slice(itemOffset, endOffset));

    setPageCount(Math.ceil(artworks.length / itemsPerPage));
  }, [itemOffset, itemsPerPage, artworks]);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % artworks.length;

    setItemOffset(newOffset);

    setCurrentPage(event.selected);

    scrolltoId();
  };

  const activatedVariant = (variant) => {
    const search = filterVariantActivated.find((elem) => elem === variant);

    if (!search) {
      setFilterVariantActivated((prev) => [...prev, variant]);
    } else {
      setFilterVariantActivated((prev) =>
        prev.filter((elem) => elem !== variant)
      );
    }
  };

  useEffect(() => {
    const element = document.querySelector("li.previousButton");

    if (currentPage === 0 && element) {
      element.classList.add("hidden");
    }
  });

  const scrolltoId = () => {
    let access = document.getElementById("filterBarGallery");
    let alignToTop = true;

    access?.scrollIntoView(alignToTop);
  };

  return (
    <>
      {data?.length > 0 ? (
        <>
          {filterBar && allVariants.length > 1 && (
            <div
              id="filterBarGallery"
              className="md:flex items-center space-y-3 md:space-y-0 md:space-x-6"
            >
              <p className="flex-none text-white text-[24px] lg:text-[28px] font-medium font-source leading-none">
                Filter by
              </p>

              <div className="flex overflow-x-auto scrollbar-hide w-full gap-2 lg:gap-6">
                {allVariants?.map((variant, index) => (
                  <div
                    key={index}
                    className={
                      "rounded-full cursor-pointer px-5 py-2 lg:py-3 border " +
                      (filterVariantActivated.find((elem) => elem === variant)
                        ? "border-[#33c7b6]"
                        : "border-white")
                    }
                    onClick={() => activatedVariant(variant)}
                  >
                    <div className="flex space-x-3 items-center ">
                      <p className="text-white font-source capitalize font-medium leading-6 text-[22px] lg:text-[28px] ">
                        {variant}
                      </p>

                      <div className="rounded-full border border-white p-1">
                        <BsFillCircleFill
                          className={
                            "w-2 h-2 lg:w-2.5 lg:h-2.5 " +
                            (filterVariantActivated.find(
                              (elem) => elem === variant
                            )
                              ? "text-[#33c7b6]"
                              : "text-transparent")
                          }
                        />
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}

          <div
            id="artworksGallery"
            className={
              "w-full h-full pr-6 md:pr-8 " +
              (minColumns === 2
                ? "columns-2 md:columns-3 gap-4 md:gap-7 lg:gap-10"
                : "columns-1 sm:columns-2 lg:columns-3 gap-10")
            }
          >
            {currentArtworks?.map((artwork, index) => (
              <div
                key={artwork?.tokenId}
                className={"lg:hover:scale-[1.1] ease-in duration-100"}
              >
                <div
                  className={
                    "inline-block " +
                    (minColumns === 2 ? "mb-3 md:mb-6 lg:mb-9" : "mb-9")
                  }
                >
                  {fullSizeOnClick ? (
                    <img
                      src={artwork?.image}
                      className="flex w-full h-auto cursor-pointer "
                      alt={artwork?.name}
                      onClick={() => {
                        setCurrentArtwork(artwork);
                        setOpenFullImageModal(true);
                      }}
                    />
                  ) : (
                    <div className="relative">
                      <Link
                        to={`/artist/${
                          artwork?.artistId
                        }/artwork/${artwork?.name.replaceAll(" ", "-")}`}
                      >
                        <img
                          src={artwork?.image}
                          className="flex w-full h-full "
                          alt={artwork?.name}
                        />

                        {artwork?.artworkSizeSoldOut && (
                          <div className="absolute top-4 right-4 flex items-center h-[20px] border border-[#33C7B6] text-[#33C7B6] bg-[#00263A] font-source text-xs px-[9px] rounded-full font-bold">
                            SOLD OUT
                          </div>
                        )}
                      </Link>
                    </div>
                  )}

                  <div
                    className={
                      "text-white py-2 leading-none space-y-2 " +
                      (minColumns === 2 && "hidden lg:flex flex-col")
                    }
                  >
                    <div className="flex justify-between space-x-2">
                      <p className="text-[21px] lg:font-medium">
                        {artwork?.name}
                      </p>

                      {!artwork?.artworkSizeSoldOut && (
                        <div className="flex-none">
                          {artwork?.bestSeller ? (
                            <div className="flex items-center h-[20px] bg-[#33C7B6] text-[#00263A] font-source text-xs px-[9px] rounded-full font-bold">
                              <span>Best seller</span>
                            </div>
                          ) : artwork?.nftsLeft?.state ? (
                            <div className="flex items-center h-[20px] bg-[#FFE587] text-[#00263A] font-source text-xs px-[9px] rounded-full font-bold">
                              <span>
                                {artwork?.nftsLeft?.nbNftsLeft +
                                  (artwork?.nftsLeft?.nbNftsLeft > 1
                                    ? " NFTs left"
                                    : " NFT left")}
                              </span>
                            </div>
                          ) : artwork?.theGalleryChoice ? (
                            <div className="flex items-center h-[20px] bg-[#EB9F9F] text-[#00263A] font-source text-xs px-[9px] rounded-full font-bold">
                              <span>Our choice</span>
                            </div>
                          ) : artwork?.mostValued ? (
                            <div className="flex items-center h-[20px] bg-[#E19AEE] text-[#00263A] font-source text-xs px-[9px] rounded-full font-bold">
                              <span>Most valued</span>
                            </div>
                          ) : null}
                        </div>
                      )}
                    </div>

                    <div className="flex justify-between space-x-2 ">
                      <p className="text-[16px] leading-6 hover:underline">
                        <Link
                          to={`/artist/${
                            artwork?.artistId
                          }/collection/${artwork?.collectionName?.replaceAll(
                            " ",
                            "-"
                          )}`}
                        >
                          {artwork?.collectionName}
                        </Link>
                      </p>

                      {price && (
                        <p className="text-lg leading-6 flex-none">
                          {totalPriceWithVAT(
                            artwork?.price,
                            artwork?.artworkSize,
                            "EUR"
                          ) + " €"}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>

          {pagination && (
            <ReactPaginate
              breakLabel="..."
              nextLabel=">"
              forcePage={currentPage}
              onPageChange={handlePageClick}
              pageRangeDisplayed={5}
              pageCount={pageCount}
              previousLabel="<"
              previousClassName="previousButton"
              nextClassName="nextButton"
              className="flex items-center space-x-6 text-white py-14 justify-center"
            />
          )}

          <Modal
            open={openFullImageModal}
            onClose={() => {
              setOpenFullImageModal(false);
            }}
            className="flex-1 bg-black"
          >
            <div className="relative flex justify-center items-center w-full h-full">
              <img
                className="object-contain w-full lg:max-h-[90%]"
                src={currentArtwork?.image}
                alt=""
              />

              <div className="absolute top-6 right-6 border-2 border-white rounded-full p-1 cursor-pointer">
                <RxCross2
                  className="sm:w-6 sm:h-6 w-7 h-7 cursor-pointer text-white"
                  onClick={() => setOpenFullImageModal(false)}
                />
              </div>
            </div>
          </Modal>
        </>
      ) : (
        <div className="flex w-full min-h-[300px] max-h-[500px] lg:min-h-[500px] lg:max-h-[800px] items-center justify-center">
          <p className="font-source text-[28px] md:text-[36px] text-white">
            No items to display
          </p>
        </div>
      )}
    </>
  );
};

export default ArtworksSection;

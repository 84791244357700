import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import useScrollPosition from "../hooks/useScrollPosition";
import { useExchangeRate } from "../hooks/usePriceConversion";
import CircularProgress from "@mui/material/CircularProgress";
import Header from "../components/Header";
import StickBar from "../components/StickBar";
import CarouselBig from "../components/CarouselBig";
import CarouselSmall from "../components/CarouselSmall";
import ArtCard from "../components/ArtCard";
import NewsletterModal from "../sections/HomePage/NewsletterModal";
import { SERVER_URL } from "../globalVariables";
import Newsletter from "../sections/HomePage/Newsletter";
import BuyButton from "../components/BuyButton";
import PurchaseModals from "../components/PurchaseModals";

const HomeNew = () => {
  const [isLoading, setIsLoading] = useState(true);
  const scroll = useScrollPosition();
  const [artworks, setArtworks] = useState([]);
  const [artists, setArtists] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  useEffect(() => {
    const scrolltoId = () => {
      let access = document.getElementById("artists");
      let alignToTop = true;

      access?.scrollIntoView(alignToTop);
    };

    if (location.hash === "#artists") {
      scrolltoId();
    }
  }, [location, artworks]);

  useEffect(() => {
    const getArtists = () => {
      fetch(`${SERVER_URL}/api/getArtists`)
        .then((response) => response.json())
        .then((data) => {
          if (data) {
            setArtists(data);
          }
        })
        .catch((error) => console.log(error));
    };

    getArtists();
  }, []);

  useEffect(() => {
    const getArtworks = () => {
      fetch(`${SERVER_URL}/api/getAllArtworks`)
        .then((response) => response.json())
        .then((data) => {
          if (data) {
            setArtworks(data);
            setIsLoading(false);
          }
        })
        .catch((error) => console.error(error));
    };

    getArtworks();
  }, []);

  return (
    <div className="flex-1 lg:max-w-[1440px] lg:mx-auto">
      <Header moreStyle="sticky" logo={scroll > 100 ? true : false} />

      <div className="flex w-full">
        <StickBar />

        {isLoading ? (
          <div className="flex w-full justify-center items-center h-[90vh]">
            <CircularProgress
              sx={{ color: "#33c7b6" }}
              style={{ height: 60, width: 60 }}
            />
          </div>
        ) : (
          <div className="w-full lg:pl-[110px]">
            <div className="pl-6 md:pl-8 lg:pl-4 space-y-14 pb-14">
              <div className="flex flex-col w-full items-center space-y-4 lg:space-y-8 pr-6 md:pr-8">
                <img
                  className="inline object-cover w-[180px] lg:w-[220px]"
                  src="/images/logo.png"
                  alt="logo"
                />

                <h2 className="text-[28px] lg:text-[34px] text-white font-medium text-center w-full">
                  TheGallery is a place dedicated to photography and fine
                  digital art.
                </h2>
              </div>

              <div className="space-y-14 lg:space-y-20">
                {artworks.favoriteArtworks?.length > 0 && (
                  <div className="w-full space-y-3 pr-6 md:pr-8">
                    <h2 className="text-[28px] lg:text-[36px] text-white font-bold w-full">
                      Discover our artists
                    </h2>

                    <CarouselBig>
                      {artworks.favoriteArtworks.map((artwork) => (
                        <BannerCarouselComponent
                          key={artwork?.tokenId}
                          data={artwork}
                        />
                      ))}
                    </CarouselBig>
                  </div>
                )}

                {artworks.bestSellersArtworks?.length > 0 && (
                  <div className="flex flex-col w-full space-y-3">
                    <h2 className="text-[28px] lg:text-[36px] text-white font-bold w-full leading-none">
                      Best Sellers
                    </h2>

                    <p className="text-lg text-white font-medium w-full">
                      Discover the most purchased artworks
                    </p>

                    <div className="min-h-[300px] lg:min-h-[400px] lg:max-h-auto text-white">
                      <CarouselSmall>
                        {artworks.bestSellersArtworks.map((artwork) => (
                          <ArtworkCarouselComponent
                            key={artwork?.tokenId}
                            data={artwork}
                          />
                        ))}
                      </CarouselSmall>
                    </div>
                  </div>
                )}

                {artworks.latestArtworks?.length > 0 && (
                  <div className="flex flex-col w-full space-y-3">
                    <h2 className="text-[28px] lg:text-[36px] text-white font-bold w-full leading-none">
                      Last Call
                    </h2>

                    <p className="text-lg text-white font-medium w-full">
                      Get the latest copy of your favorite artwork
                    </p>

                    <div className="min-h-[300px] lg:min-h-[400px] lg:max-h-auto text-white">
                      <CarouselSmall>
                        {artworks.latestArtworks.map((artwork) => (
                          <ArtworkCarouselComponent
                            key={artwork?.tokenId}
                            data={artwork}
                          />
                        ))}
                      </CarouselSmall>
                    </div>
                  </div>
                )}

                {artists?.length > 0 && (
                  <div className="w-full space-y-3">
                    <h2 className="text-[28px] lg:text-[36px] text-white font-bold w-full leading-none">
                      Our artists
                    </h2>

                    <p className="text-lg text-white font-medium w-full">
                      Discover the talents that make TheGallery
                    </p>

                    <CarouselSmall>
                      {artists?.map(
                        (artist) =>
                          artist.status === "active" && (
                            <ArtistCarouselComponent
                              key={artist?.artistId}
                              data={artist}
                            />
                          )
                      )}
                    </CarouselSmall>
                  </div>
                )}

                {artworks?.upComingArtworks?.length > 0 && (
                  <div className="w-full space-y-3">
                    <h2 className="text-[28px] lg:text-[36px] text-white font-bold w-full leading-none">
                      Coming soon
                    </h2>

                    <p className="text-lg text-white font-semibold w-full">
                      Those artworks will soon be available on The Gallery
                    </p>

                    <div className="min-h-[300px] lg:min-h-[400px] lg:max-h-auto text-white">
                      <CarouselSmall>
                        {artworks?.upComingArtworks.map((artwork) => (
                          <ComingSoonCarouselComponent
                            key={artwork?.name}
                            data={artwork}
                          />
                        ))}
                      </CarouselSmall>
                    </div>
                  </div>
                )}

                <div className="w-full pr-6 md:pr-8">
                  <Newsletter />
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

      <NewsletterModal />

      <PurchaseModals />
    </div>
  );
};

export default HomeNew;

const BannerCarouselComponent = ({ data }) => {
  const artistPath = `/artist/${data?.artistId}`;
  const collectionPath = `/collection/${data?.collectionName.replaceAll(
    " ",
    "-"
  )}`;
  const { totalPriceWithVAT } = useExchangeRate();
  return (
    <div
      key={data?.tokenId}
      className="relative h-full max-h-[600px] md:max-h-[700px] flex items-center"
    >
      <div
        className="-z-10 absolute h-full w-full bg-cover bg-center bg-no-repeat blur-lg"
        style={{ backgroundImage: `url(${data?.image})` }}
      ></div>

      <div className="grid grid-cols-3 gap-10 md:gap-12 p-10 sm:px-20 lg:py-24 w-full">
        <div className="col-span-3 lg:col-span-2 flex items-center justify-center">
          <img
            src={data?.image}
            className={`shrink w-full max-h-[200px] md:max-h-[300px] lg:max-h-[400px] 2xl:max-h-[500px] object-cover`}
            alt={data?.name}
          />
        </div>

        <div className="col-span-3 lg:col-span-1 flex items-center justify-center">
          <div className="text-white lg:flex flex-col lg:flex-none mt-2 w-full text-left space-y-10 lg:space-y-14">
            <div>
              <Link
                to={artistPath + `/artwork/${data?.name.replaceAll(" ", "-")}`}
                className="text-2xl lg:text-4xl hover:underline"
              >
                {data?.name}
              </Link>

              <p className="text-lg font-source hover:underline">
                <Link to={artistPath + collectionPath}>
                  {data?.collectionName}
                </Link>
              </p>

              <p className="font-source">
                {totalPriceWithVAT(data?.price, data?.artworkSize, "EUR") +
                  " €"}
              </p>
            </div>

            <div className="flex flex-col space-y-4 xl:space-y-0 xl:flex-row xl:space-x-4 ">
              <BuyButton artworkData={data} design="primary" />

              <Link
                to={artistPath + collectionPath}
                className="border-2 text-center border-white rounded-full text-white px-6 py-2 font-source flex-none"
              >
                See the collection
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const ComingSoonCarouselComponent = ({ data }) => {
  const artistPath = `/artist/${data?.artistId}`;
  const collectionPath = `/collection/${data?.collectionName.replaceAll(
    " ",
    "-"
  )}`;

  const title = (
    <Link to={artistPath}>{data.artistId.replaceAll("-", " ")}</Link>
  );

  const collection = (
    <Link to={artistPath + collectionPath}>{data?.collectionName}</Link>
  );

  function getMonthAndYear(seconds) {
    const date = new Date(seconds * 1000);
    const options = { year: "numeric", month: "long" };
    const monthAndYear = date.toLocaleDateString("fr-FR", options);
    return monthAndYear;
  }
  const button = (
    <Link
      className="border-2 border-[#33c7b6] rounded-full text-[#33c7b6] px-7 py-2 font-source"
      to={artistPath + collectionPath}
    >
      Discover
    </Link>
  );

  return (
    <ArtCard
      imageLink={artistPath + collectionPath}
      image={data.image}
      alt={data.name}
      title={title}
      collection={collection}
      button={button}
      description={getMonthAndYear(data?.releaseDate?._seconds)}
    />
  );
};

const ArtworkCarouselComponent = ({ data }) => {
  const { totalPriceWithVAT } = useExchangeRate();
  const artistPath = `/artist/${data?.artistId}`;
  const collectionPath = `/collection/${data?.collectionName.replaceAll(
    " ",
    "-"
  )}`;
  const price = totalPriceWithVAT(data?.price, data?.artworkSize, "EUR") + " €";

  const title = (
    <Link to={artistPath}>{data.artistId.replaceAll("-", " ")}</Link>
  );
  const collection = (
    <Link to={artistPath + collectionPath}>{data?.collectionName}</Link>
  );

  const button = <BuyButton artworkData={data} design="secondary" />;
  return (
    <ArtCard
      imageLink={`/artist/${data?.artistId}/artwork/${data?.name.replaceAll(
        " ",
        "-"
      )}`}
      image={data.image}
      alt={data.name}
      title={title}
      collection={collection}
      price={price}
      button={button}
    />
  );
};

const ArtistCarouselComponent = ({ data }) => {
  const artistPath = `/artist/${data?.artistId}`;
  const title = (
    <Link to={artistPath}>{data?.artistId.replaceAll("-", " ")}</Link>
  );

  return (
    <ArtCard
      imageLink={artistPath}
      image={data.profilePicture}
      alt={data.artistId}
      title={title}
      description={data.slogan}
      showButton={false}
      design="secondary"
    />
  );
};

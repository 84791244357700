import React from "react";
import { Link } from "react-router-dom";

const CollectionsSection = ({ data }) => {
  return (
    <div className="pr-6 md:pr-8 pt-5 md:pt-8 pb-10 space-y-16 ">
      {data?.map((collection, index) => (
        <div
          key={index}
          className="md:flex md:space-x-10 lg:space-x-16 xl:space-x-20 items-center"
        >
          <div className="md:max-w-[370px] lg:max-w-[460px] xl:max-w-[550px] text-white space-y-4">
            <div className="space-y-2 lg:space-y-4">
              <p className="text-[35px] lg:text-[44px] leading-[35px] lg:leading-[44px] font-medium">
                {collection?.name}
              </p>

              <div className="flex text-[18px] lg:text-[28px] font-medium space-x-4 lg:space-x-20">
                <p className="">
                  {collection?.nbOfArtworks +
                    (collection?.nbOfArtworks == 1 ? " NFT" : " NFTs")}
                </p>

                <p className="">{collection?.yearOfProd}</p>
              </div>
            </div>

            <img
              className="flex md:hidden object-cover w-full max-h-[300px]"
              src={collection?.coverPicture}
              alt=""
            />

            <p
              className={`text-base lg:text-xl line-clamp-3 lg:line-clamp-4 xl:line-clamp-7`}
              dangerouslySetInnerHTML={{
                __html: collection?.description,
              }}
            />
            
            <div className="flex w-full justify-center pt-2 lg:pt-4">
              <Link
                className="text-[#00263a] py-2 px-7 bg-white rounded-full text-[17px] font-source"
                to={`/artist/${
                  collection?.artistId
                }/collection/${collection?.name.replaceAll(" ", "-")}`}
                state={collection}
              >
                Show collection
              </Link>
            </div>
          </div>

          <div className="hidden md:flex w-full">
            <img
              className="object-cover w-full max-h-[400px]"
              src={collection?.coverPicture}
              alt=""
            />
          </div>
        </div>
      ))}
    </div>
  );
};

export default CollectionsSection;

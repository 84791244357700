import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import useAuth from "../hooks/useAuth";
import Header from "../components/Header";
import StickBar from "../components/StickBar";
import Modal from "@mui/material/Modal";
import { AiOutlineCheckCircle } from "react-icons/ai";
import { BsCreditCard } from "react-icons/bs";
import Checkbox from "@mui/material/Checkbox";
import {
  useContractWrite,
  usePrepareContractWrite,
  useWaitForTransaction,
} from "wagmi";
import { polygon, polygonMumbai } from "wagmi/chains";
import abiTheGallerySales from "../json/PreSale.json";
import { ethers } from "ethers";
import { RxCross2 } from "react-icons/rx";
import { IoIosAlert } from "react-icons/io";
import CircularProgress from "@mui/material/CircularProgress";
import { isBrowser } from "react-device-detect";
import {
  TG_APP_URL,
  TG_LP_URL,
  SM_PRESALE_ADDRESS,
  SERVER_URL,
  chainId,
  chainIdHex,
} from "../globalVariables";

const PrivateSales = () => {
  const {
    currentUser,
    moreInfoCurrentUser,
    setOpenConnexionModal,
    setBuyNowActive,
    setOpenPaymentModal,
    openPaymentModal,
    setOpenSwitchNetworkModal,
    setOpenConfirmEmailModal,
    setOpenVerifyMetamaskExtensionModal,
    ResendEmailVerification,
  } = useAuth();
  const navigate = useNavigate();
  const [openIsPendingModal, setOpenIsPendingModal] = useState(false);
  const [openSuccessfulModal, setOpenSuccessfulModal] = useState(false);
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [selectArtist, setSelectArtist] = useState({
    SaerSaid: true,
  });
  const [termsError, setTermsError] = useState(false);
  const [termsAndConditions, setTermsAndConditions] = useState(false);
  const [errorSelectArtist, setErrorSelectArtist] = useState(false);
  const [wagmiState, setWagmiState] = useState(false);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const nftNameParams = queryParams.get("artworkName");
  const successParams = queryParams.get("success");
  const canceledParams = queryParams.get("canceled");
  const checkoutSessionId = window.localStorage.getItem("checkoutSessionId");
  const [euroPrice, setEuroPrice] = useState(null);
  const nftPrice = "50000000000000000000";
  const nftName = "Snow Leopard";

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const PriceConversion = async () => {
      const artworkMaticPrice = formatEther(nftPrice);

      fetch(
        `${SERVER_URL}/api/priceConversion?amountMatic=${artworkMaticPrice}`
      )
        .then((response) => response.json())
        .then((data) => {
          if (data) {
            const price = data.price;

            setEuroPrice(price.toFixed(2));
            setWagmiState(true);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };

    PriceConversion();
  }, []);

  const { config } = usePrepareContractWrite({
    address: SM_PRESALE_ADDRESS,
    chainId: chainId,
    abi: abiTheGallerySales,
    functionName: "buy",
    args: [selectArtist?.SaerSaid && "Saer Said"],
    overrides: {
      value: "50000000000000000000",
      gasLimit: 450000,
    },
    enabled: wagmiState,
  });

  const { data, error, write } = useContractWrite(config);

  const { isLoading } = useWaitForTransaction({
    chainId: chainId,
    hash: data?.hash,
    onSuccess(data) {
      setOpenIsPendingModal(false);
      setOpenSuccessfulModal(true);
    },
    onError(error) {
      setOpenIsPendingModal(false);
      setOpenErrorModal(true);
      console.log("Error", error);
    },
  });

  useEffect(() => {
    if (isLoading) {
      setOpenIsPendingModal(true);
    } else if (error) {
      setOpenErrorModal(true);
    }
  }, [isLoading, error]);

  const buyNow = () => {
    if (!currentUser) {
      setOpenConnexionModal(true);
      setBuyNowActive(true);
    } else if (
      !currentUser?.emailVerified &&
      moreInfoCurrentUser?.provider === "firebase"
    ) {
      setOpenConfirmEmailModal(true);
      ResendEmailVerification();
      setBuyNowActive(true);
    } else {
      setOpenPaymentModal(true);
    }
  };

  const buyArtworkByWallet = async () => {
    if (termsAndConditions && currentUser) {
      if (window.ethereum === undefined || !window.ethereum.isMetaMask) {
        if (isBrowser) {
          return setOpenVerifyMetamaskExtensionModal(true);
        } else {
          window.location.replace(
            `https://metamask.app.link/dapp/${TG_APP_URL}`
          );
        }
      } else if (
        moreInfoCurrentUser?.provider === "moralis" &&
        window.ethereum.chainId !== chainIdHex
      ) {
        setOpenSwitchNetworkModal(true);
      } else {
        write();
      }
    } else {
      setTermsError(true);
    }
  };

  const buyArtworkByCard = async () => {
    if (termsAndConditions && currentUser) {
      try {
        if (!euroPrice) {
          throw new Error("Error in get the price in euros");
        }

        const path = window.location.href;

        let params = {
          artworkName: "Private Sales Saer Said",
          price: euroPrice,
          successUrl: `${path}?artworkName=${nftName.replaceAll(
            " ",
            "-"
          )}&success=true`,
          cancelUrl: `${path}?artworkName=${nftName.replaceAll(
            " ",
            "-"
          )}&canceled=true`,
        };

        let options = {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          method: "POST",
          body: JSON.stringify(params),
        };

        let res = await fetch(
          `${SERVER_URL}/api/createCheckoutSession`,
          options
        );
        res = await res.json();

        if (res?.id) {
          window.localStorage.setItem("checkoutSessionId", res?.id);

          window.location.href = res.url;
        } else {
          throw new Error("Error when creating checkout session");
        }
      } catch (error) {
        setOpenErrorModal(true);
        console.log(error);
      }
    } else {
      setTermsError(true);
    }
  };

  const getCheckoutSession = async (uid) => {
    try {
      if (!uid) {
        throw new Error("Uid parameter is required.");
      }

      let options = {
        headers: {
          Accept: "application/json",
        },
      };

      let res = await fetch(
        `${SERVER_URL}/api/getCheckoutSession/${uid}`,
        options
      );
      res = await res.json();

      return res;
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const checkoutSessionStatus = async () => {
      try {
        const res = await getCheckoutSession(checkoutSessionId);

        if (res?.id) {
          if (successParams && res?.status === "complete") {
            setOpenSuccessfulModal(true);
            window.localStorage.removeItem("checkoutSessionId");
          }

          if (canceledParams && res?.status !== "complete") {
            setOpenErrorModal(true);
            window.localStorage.removeItem("checkoutSessionId");
          }
        }

        setOpenIsPendingModal(false);
      } catch (error) {
        console.log(error);
      }
    };

    if (
      (successParams || canceledParams) &&
      nftNameParams &&
      checkoutSessionId
    ) {
      setOpenIsPendingModal(true);
      checkoutSessionStatus();
    }
  }, [successParams, canceledParams, nftNameParams, checkoutSessionId]);

  const formatEther = (price) => {
    return ethers.utils.formatEther(price);
  };

  const refreshPage = () => {
    navigate(location.pathname);
  };

  return (
    <div className="lg:max-w-[1440px] lg:mx-auto">
      <Header moreStyle="sticky bg-[#00263a]" logo={true} />

      <div className="flex">
        <StickBar />

        <div className="flex w-full lg:pl-[110px] justify-center items-center min-h-[800px] lg:h-[calc(100vh-100px)]">
          <div className="space-y-16 md:space-y-20 py-16 px-10 sm:px-28 md:px-10 lg:px-0">
            <div className="space-y-20 ">
              <div className="space-y-6 text-white text-center">
                <p className="text-[23px] md:text-[28px]">
                  Join Saer's adventure to shoot the snow leopard!
                </p>

                <p className="text-[19px] md:text-[24px]">
                  The private sales will give you exclusive access to one of
                  those pictures...
                </p>
              </div>

              <div className="space-y-14 md:space-y-0 flex flex-col md:flex-row md:space-x-12 lg:space-x-36 justify-center">
                <div className="space-y-4 md:space-y-8">
                  <img
                    className="md:w-[600px] h-auto object-contain"
                    src="/images/Saer-Said-nft.jpg"
                    alt=""
                  />

                  <div className="flex flex-col space-y-2 text-white text-center items-center">
                    <div className="flex space-x-5 items-center">
                      <a
                        href="https://saersaid.com/"
                        target="_blank"
                        rel="noreferrer"
                        className="text-[28px] hover:underline"
                      >
                        Saer Said
                      </a>

                      {/*<div
                        className="rounded-md border-2 border-white p-1 cursor-pointer"
                        onClick={() =>
                          setSelectArtist({
                            ...selectArtist,
                            SaerSaid: !selectArtist?.SaerSaid,
                          })
                        }
                      >
                        <BsFillSquareFill
                          className={
                            "w-3.5 h-3.5 " +
                            (selectArtist?.SaerSaid
                              ? "text-[#33c7b6]"
                              : "text-transparent")
                          }
                        />
                      </div>*/}
                    </div>

                    <p className="text-[21px]">
                      Photographer of the extreme, people and wildlife
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="flex flex-col w-full items-center space-y-4">
              {errorSelectArtist && (
                <div className="">
                  <span className="block font-bold text-[#E02020]">
                    Please select at least one artist
                  </span>
                </div>
              )}

              <button
                className="text-[17px] text-[#00263A] rounded-full bg-white px-8 py-2 font-source cursor-pointer"
                onClick={() => {
                  selectArtist?.SaerSaid
                    ? buyNow()
                    : setErrorSelectArtist(true);
                }}
              >
                Buy now
              </button>
            </div>
          </div>
        </div>
      </div>

      <Modal
        open={openPaymentModal}
        onClose={() => {
          setOpenPaymentModal(false);
          setTermsError(false);
          setErrorSelectArtist(false);
          setTermsAndConditions(false);
        }}
        className="flex items-center justify-center"
      >
        <div className="flex flex-col bg-white sm:rounded-xl w-[550px] h-full overflow-auto scrollbar-hide sm:h-[770px] outline-none">
          <div className="flex p-6 w-full justify-end">
            <RxCross2
              className="w-11 h-11 cursor-pointer"
              onClick={() => {
                setOpenPaymentModal(false);
                setTermsError(false);
                setErrorSelectArtist(false);
                setTermsAndConditions(false);
              }}
            />
          </div>

          <div className="flex flex-col items-center px-10 sm:px-16 space-y-12 text-[#00263a]">
            <div className="flex flex-col items-center space-y-14">
              <img className="w-40" src="/images/logo-blue.png" alt="" />

              <p className="text-[20px] sm:text-[24px] font-black text-center font-source">
                Soon in your collection
              </p>
            </div>

            <div className="flex flex-col space-y-10 ">
              <div className="flex space-x-4 items-center">
                <div className="w-2/3">
                  <img
                    className="w-full h-full object-cover"
                    src="/images/Saer-Said-nft.jpg"
                    alt=""
                  />
                </div>

                <div className="flex flex-col space-y-1">
                  <p className="font-black font-source leading-[30px] text-[18px] sm:text-[24px]">
                    Your NFT
                  </p>

                  <p className="font-source text-base sm:text-[20px]">
                    {formatEther(nftPrice) + " "}
                    Matic
                  </p>
                </div>
              </div>

              <div className="flex flex-col font-source space-y-3">
                <div className="flex space-x-2 ">
                  <Checkbox
                    sx={{
                      color: "silver",
                      "&.Mui-checked": { color: "#00263a" },
                    }}
                    onClick={(event) =>
                      setTermsAndConditions(event.target.checked)
                    }
                  />

                  <p className="">
                    Accept the{" "}
                    <Link
                      className="underline cursor-pointer"
                      to={`/CGV-CGU-THEGALLERY_V1.0.pdf`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      « Private Sales Terms&Agreements »
                    </Link>{" "}
                  </p>
                </div>

                {termsError && (
                  <div className="">
                    <span className="block text-sm font-medium text-[#FD866C] font-source">
                      Please accept « Private Sales Terms&Agreements »
                    </span>
                  </div>
                )}
              </div>

              <div className="flex flex-col space-y-3 w-full items-center">
                {moreInfoCurrentUser?.provider === "moralis" && (
                  <button
                    className="flex bg-[#00263a] space-x-2 w-1/2 font-source py-2 rounded-full cursor-pointer text-white items-center justify-center"
                    onClick={buyArtworkByWallet}
                  >
                    <span className="">Payment</span>

                    <img
                      className="w-5"
                      src="/images/icon-metamask.png"
                      alt=""
                    />
                  </button>
                )}

                <button
                  className="flex border-2 border-[#00263a] space-x-3 text-[#00263a] w-1/2 font-source py-1.5 rounded-full cursor-pointer items-center justify-center"
                  onClick={buyArtworkByCard}
                >
                  <span>Pay by card</span>

                  <BsCreditCard className="w-5 h-5 text-[#00263a]" />
                </button>
              </div>

              <div className="flex pb-10 justify-center font-source">
                <p className="font-medium text-[19px]">
                  Any question ?{" "}
                  <a
                    className="font-bold underline cursor-pointer"
                    href={`${TG_LP_URL}/faq`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    See our FAQ
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </Modal>

      <Modal
        open={openSuccessfulModal}
        onClose={() => {
          setOpenSuccessfulModal(false);
          setOpenPaymentModal(false);
          refreshPage();
        }}
        className="flex items-center justify-center"
      >
        <div className="flex flex-col bg-white sm:rounded-xl overflow-auto scrollbar-hide w-full sm:w-[550px] h-full sm:h-[770px] outline-none">
          <div className="flex p-6 w-full justify-end">
            <RxCross2
              className="w-11 h-11 cursor-pointer"
              onClick={() => {
                setOpenSuccessfulModal(false);
                setOpenPaymentModal(false);
                refreshPage();
              }}
            />
          </div>

          <div className="flex flex-col space-y-20 text-[#00263a]">
            <div className="flex space-x-1 items-center justify-center">
              <img className="w-40" src="/images/logo-blue.png" alt="" />
            </div>

            <div className="flex flex-col space-y-16 px-8 sm:px-16 items-center">
              <div className="flex flex-col items-center space-y-6">
                <AiOutlineCheckCircle className="w-12 h-12 text-[#33c7b6]" />

                <p className="text-[20px] sm:text-[24px] font-black text-center">
                  Congratulations on your NFT!
                </p>
              </div>

              <div className="text-[19px] sm:text-[21px] space-y-6 text-center w-[340px]">
                <p className="">
                  Stay Tuned and be informed about the next steps and reveal
                  date!
                </p>

                <p>
                  Follow-us on our{" "}
                  <a
                    href="https://twitter.com/TGallery_NFT"
                    target="_blank"
                    rel="noreferrer"
                    className="font-bold underline"
                  >
                    Tweeter Account
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </Modal>

      <Modal
        open={openErrorModal}
        onClose={() => {
          setOpenErrorModal(false);
          refreshPage();
        }}
        className="flex items-center justify-center"
      >
        <div className="flex flex-col bg-white sm:rounded-xl setOpenSuccessfulModal w-full sm:w-[520px] h-full sm:h-[570px] outline-none relative space-y-20">
          <div className="">
            <div className="flex justify-end items-center p-6">
              <RxCross2
                className="w-11 h-11 cursor-pointer"
                onClick={() => {
                  setOpenErrorModal(false);
                  refreshPage();
                }}
              />
            </div>

            <div className="flex justify-center">
              <img className="w-40" src="/images/logo-blue.png" alt="" />
            </div>
          </div>

          <div className="flex flex-col space-y-10 items-center text-center">
            <IoIosAlert className="h-14 w-14 text-[#FD866C]" />

            <div className="space-y-2 items-center text-[#00263a] ">
              <p className="text-[24px] font-black">An error has occurred.</p>

              <p className="text-[24px] font-black">
                Please try again later...
              </p>
            </div>
          </div>
        </div>
      </Modal>

      <Modal
        open={openIsPendingModal}
        onClose={() => {
          setOpenIsPendingModal(false);
        }}
        className="flex items-center justify-center"
      >
        <div className="flex flex-col bg-white sm:rounded-xl overflow-auto scrollbar-hide w-full sm:w-[520px] h-full sm:h-[570px] outline-none relative space-y-20">
          <div className="">
            <div className="flex justify-end items-center p-6">
              <RxCross2
                className="w-11 h-11 cursor-pointer"
                onClick={() => {
                  setOpenIsPendingModal(false);
                }}
              />
            </div>

            <div className="flex justify-center">
              <img className="w-40" src="/images/logo-blue.png" alt="" />
            </div>
          </div>

          <div className="flex flex-col space-y-20 items-center px-16">
            <p className="text-[#00263a] text-center text-[24px] font-bold">
              Purchase in progress, please wait.
            </p>

            <CircularProgress
              sx={{ color: "#33c7b6" }}
              style={{ height: 60, width: 60 }}
            />
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default PrivateSales;

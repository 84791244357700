import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import StickBar from "../components/StickBar";
import ArtworksGallery from "../components/ArtworksGallery";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import {
  getDoc,
  doc,
  getCountFromServer,
  collection,
  query,
  where,
} from "firebase/firestore";
import { db } from "../firebase";
import { TfiWorld, TfiFacebook } from "react-icons/tfi";
import { BsInstagram, BsTwitter } from "react-icons/bs";
import { SERVER_URL } from "../globalVariables";
import CircularProgress from "@mui/material/CircularProgress";
import useWindowsResize from "../hooks/useWindowsResize";
import { Link } from "react-router-dom";

const ArtistCollection = () => {
  const routerData = useLocation().state;
  const navigate = useNavigate();
  const { artistId, collectionName } = useParams();
  const [artistData, setArtistData] = useState(null);
  const [collectionData, setCollectionData] = useState(null);
  const [nbOfCollections, setNbOfCollections] = useState(0);
  const [nbOfArtworks, setNbOfArtworks] = useState(0);
  const [artworks, setArtworks] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const screenSize = useWindowsResize();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const getArtist = async () => {
      const docRef = doc(db, "artists", artistId);

      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        setArtistData(docSnap.data());
      } else {
        navigate(`/`);
      }
    };

    const getCollection = async () => {
      const name = collectionName.replaceAll("-", " ");

      const docRef = doc(db, `artists/${artistId}/collections/`, name);

      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        setCollectionData(docSnap.data());
      } else {
        navigate(`/artist/${artistId}`);
      }
    };

    getArtist();

    if (routerData) {
      setCollectionData(routerData);
    } else {
      getCollection();
    }
  }, [artistId, collectionName]);

  useEffect(() => {
    const getNbOfArtworks = async () => {
      const collRef = collection(db, "artworks");

      const q = query(
        collRef,
        where("artistId", "==", artistId),
        where("artworkId", "==", 1)
      );

      const snapshot = await getCountFromServer(q);

      setNbOfArtworks(snapshot.data().count);
    };

    const getNbOfCollections = async () => {
      const collRef = collection(db, `artists/${artistId}/collections`);

      const q = query(collRef, where("artistId", "==", artistId));

      const snapshot = await getCountFromServer(q);

      setNbOfCollections(snapshot.data().count);
    };

    if (artistData) {
      getNbOfArtworks();
      getNbOfCollections();
    }
  }, [artistData]);

  useEffect(() => {
    const getArtworksByCollection = () => {
      fetch(
        `${SERVER_URL}/api/getArtworksByCollection?artistId=${artistId}&collectionName=${collectionName}`
      )
        .then((response) => response.json())
        .then((data) => {
          if (data) {
            setArtworks(data);
            setIsLoading(false);
          } else {
            navigate("/");
          }
        })
        .catch((error) => {
          console.log(error);
          navigate("/");
        });
    };

    if (artistData && collectionData) {
      getArtworksByCollection();
    }
  }, [artistData, collectionData]);

  return (
    <div className="flex flex-col lg:max-w-[1440px] lg:mx-auto">
      <Header moreStyle="sticky" logo={true} />

      <div className="flex">
        <StickBar />

        {isLoading ? (
          <div className="flex w-full justify-center items-center h-[90vh]">
            <CircularProgress
              sx={{ color: "#33c7b6" }}
              style={{ height: 60, width: 60 }}
            />
          </div>
        ) : (
          <div className="w-full lg:pl-[110px]">
            <div className="w-full space-y-8 p md:space-y-10 pt-8 md:pt-10 pl-6 md:pl-8 lg:pl-4">
              <div className="md:flex w-full items-center space-y-4 md:space-y-0 md:space-x-40 pr-6 md:pr-8">
                <div className="text-white md:max-w-[390px] lg:max-w-[590px] leading-none space-y-2">
                  <p className="text-[35px] lg:text-[44px]">
                    {collectionData?.name}
                  </p>

                  <p className="text-[19px] lg:text-[24px] font-medium">
                    {collectionData?.yearOfProd}
                  </p>

                  <p className="text-base lg:text-xl">
                    {collectionData?.description}
                  </p>
                </div>

                <div className="flex md:flex-col space-x-3 md:space-x-0 space-y-2 max-w-[250px] lg:max-w-[320px]">
                  <img
                    src={artistData?.profilePicture}
                    className="w-[35px] h-[35px] md:w-[60px] md:h-[60px] lg:w-[80px] lg:h-[80px] object-cover object-right-top rounded-full"
                    alt=""
                  />

                  <div className="space-y-2 text-white">
                    <Link
                      to={`/artist/${
                        artistData?.firstName + "-" + artistData?.lastName
                      }`}
                    >
                      <p className="text-[21px] font-bold underline">
                        {artistData?.firstName + " " + artistData?.lastName}
                      </p>
                    </Link>

                    <div className="flex space-x-1.5 lg:space-x-2.5">
                      <a
                        className=""
                        href={artistData?.links.website}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <TfiWorld className="w-4 h-4 lg:w-5 lg:h-5" />
                      </a>

                      <a
                        className=""
                        href={artistData?.links.instagram}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <BsInstagram className="w-4 h-4 lg:w-5 lg:h-5" />
                      </a>

                      <a
                        className=""
                        href={artistData?.links.twitter}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <BsTwitter className="w-4 h-4 lg:w-5 lg:h-5" />
                      </a>

                      <a
                        className=""
                        href={artistData?.links.facebook}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <TfiFacebook className="w-4 h-4 lg:w-5 lg:h-5 -ml-1.5" />
                      </a>
                    </div>

                    <p className="text-base lg:text-xl">
                      {artistData?.statusPro}
                    </p>

                    <div className="md:flex md:space-y-0 space-y-2 text-white md:space-x-8 items-center leading-none">
                      <p className="flex text-[16px] lg:text-[20px] items-center">
                        <span className="font-bold text-[20px] pr-2">
                          {nbOfCollections}
                        </span>
                        {nbOfCollections == 1 ? "collection" : "collections"}
                      </p>

                      <p className="flex text-[16px] lg:text-[20px] items-center">
                        <span className="font-bold text-[20px] pr-2">
                          {nbOfArtworks}
                        </span>
                        {nbOfArtworks == 1 ? "artwork" : "artworks"}
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="space-y-7 lg:space-y-10">
                <div className="space-y-1 pr-6 md:pr-8">
                  <h3 className="font-medium text-white">
                    <span className="font-black">{artworks?.length}</span>

                    {artworks?.length > 1 ? " Artworks" : " Artwork"}
                  </h3>

                  <div className="bg-white rounded-full h-1 lg:h-2 w-full"></div>
                </div>

                {isLoading ? (
                  <div className="flex justify-center items-center h-[100px]">
                    <CircularProgress
                      sx={{ color: "#33c7b6" }}
                      style={{ height: 60, width: 60 }}
                    />
                  </div>
                ) : (
                  <ArtworksGallery
                    itemsPerPage={screenSize > 768 ? 12 : 7}
                    data={artworks}
                    pagination={true}
                    filterBar={true}
                  />
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ArtistCollection;
